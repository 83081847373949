import bag from "@/assets/hud/footer/bag.png";
import battle from "@/assets/hud/footer/battle.png";
import friends from "@/assets/hud/footer/friends.png";
import menu from "@/assets/hud/footer/menu.png";
import quests from "@/assets/hud/footer/quests.png";

export const NAV_ITEMS = [
  {
    name: "МАГАЗИН",
    src: bag,
    alt: "page map of islands in menu",
    href: "/shop",
  },
  {
    name: "ДРУЗЬЯ",
    src: friends,
    alt: "page friends in menu",
    href: "/friends",
  },
  {
    name: "МЕНЮ",
    src: menu,
    alt: "page menu(main page) in menu",
    href: "/",
  },
  {
    name: "ЗАДАЧИ",
    src: quests,
    alt: "page quests in menu",
    href: "/quests",
  },  
  {
    name: "ЭКСПЕДИЦИЯ",
    src: battle,
    alt: "page drop in menu",
    href: "/expeditions",
  },
];

export const INITIAL_ACTIVE_ITEM = "Меню";
