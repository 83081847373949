import key from "@/assets/icons/key.png";
import autocollect from "@/assets/icons/autocollect.png";
import pearls from "@/assets/icons/pearls.png";
import multiplier from "@/assets/icons/multiplier.png";
import coin from "@/assets/icons/coin.png";
import mapIcon from "@/assets/icons/mapPiece.png"
import { RewardImageType } from "@/store/notification/interface";

export const getImage = (type: RewardImageType | string) => {
  switch (type) {
    case "autoCollect":
      return autocollect;
    case "keys":
      return key;
    case "pearls":
      return pearls;
    case "coins":
      return coin;
    case "map":
      return mapIcon;
    case "multiplier":
      return multiplier;
    default:
      return autocollect;
  }
};
